import ApiClient from '@/api/apiClient';
import CustomError from '@/entities/customError';
import { Image, SortingRequestParams } from '@/services/services.types';
import Author from '@/entities/blog/Author';
import ImageService from '@/services/ImageService';

class AuthorService {
    /**
     * Получение авторов
     * @param {SortingRequestParams} params - параметры запроса (sort, direction, ids, etc.)
     * @returns {Promise} - массив объектов авторов
     */

    async getAll(
        params: SortingRequestParams = {},
        type?: 'person' | 'organization'
    ): Promise<[CustomError | null, Author[] | []]> {
        const url = `/authors`;
        const defaultValue = [] as [];
        const config = { params: { ...params, type } };
        const errorPath = '[api:authors:getAll]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath, config });
    }
    /**
     * Получение отдельной автора
     * @param {String} id - id автора
     * @returns {Promise} - объект автора
     */

    async getOne(id: string): Promise<[CustomError | null, Author | null]> {
        const url = `/authors/${id}`;
        const defaultValue = null;
        const errorPath = '[api:author:getOne]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath });
    }
    /**
     * Создание автора
     * @param {Object} author - объект автора
     * @returns {Promise} - кастомный объект, содержащий ответ сервера
     */

    async createOne(author: Author): Promise<[CustomError | null, Author | null]> {
        const url = `/authors`;
        const defaultValue = null;
        const errorPath = '[api:author:createOne]';
        return await ApiClient.admin.post({ url, defaultValue, errorPath, data: author });
    }
    /**
     * Обновление автора
     * @param {Object} author - объект автора
     * @returns {Promise} - кастомный объект, содержащий ответ сервера
     */

    async updateOne(author: Author): Promise<[CustomError | null, Author | null]> {
        const url = `/authors/${author.id}`;
        const defaultValue = null;
        const errorPath = '[api:author:updateOne]';
        return await ApiClient.admin.put({ url, defaultValue, errorPath, data: author });
    }

    /**
     * Обновление нескольких авторов
     * @param {Array} authors - массив объектов авторов
     */
    async updateBatch(authors: Author[]): Promise<[CustomError | null, Author[] | []]> {
        const url = `/authors/batch`;
        const defaultValue = [] as [];
        const errorPath = '[api:catalog:updateBatch]';
        const config = { params: { ids: authors.map((author) => author.id) } };
        return await ApiClient.admin.put({
            url,
            defaultValue,
            errorPath,
            data: authors,
            config,
        });
    }

    /**
     * Удаление автора
     * @param {Object} id - объект автора
     */

    async removeOne(id: string): Promise<[CustomError | null, null]> {
        const url = `/authors/${id}`;
        const defaultValue = null;
        const errorPath = '[api:author:removeOne]';
        return await ApiClient.admin.delete({ url, defaultValue, errorPath });
    }

    /*  /!**
     * Получение товаров из конкретного каталога
     * @param {String} id - ID каталога из которого нужно получить товары
     * @param {Object} params - параметры запроса (sort, direction, ids, etc.)
     * @returns {Promise} массив объектов товаров
     *!/

    async getProductsInCatalog(id: string, params = {}): Promise<[CustomError | null, Product[] | []]> {
        const url = `/catalogs/v2/${id}/products`;
        const defaultValue = [] as [];
        const errorPath: string = '[api:catalog:getProductsInCatalog]';
        const config = { params };
        return await ApiClient.admin.get({ url, defaultValue, errorPath, config });
    }

    /!**
     * Получение связей каталога
     * @param {String} id - ID каталога, связи которого нужно получить
     * @returns {Promise} массив объектов связей каталог-товар
     *!/

    async getAllEntries(id): Promise<[CustomError | null, CatalogEntryType[] | []]> {
        const url = `/catalogs/${id}/entries`;
        const defaultValue = [] as [];
        const errorPath: string = '[api:catalog:getAllEntries]';
        return await ApiClient.admin.get({ url, defaultValue, errorPath });
    }

    /!**
     * Получение изображений каталогов
     * @param {Array} catalogs - массив каталогов, изображения которых нужно получить
     * @returns {Promise} объект вида {catalogId: Image}
     *!/
*/
    async getImages(
        authors: Author[]
    ): Promise<[CustomError | null, { [key: string]: Image } | { [key: string]: unknown }]> {
        const map = {} as { [key: string]: string };
        const imagesIds: string[] = [];
        authors.forEach((author) => {
            if (author.photoId) {
                map[author.photoId] = author.id;
                imagesIds.push(author.photoId);
            }
        });
        if (!imagesIds.length) {
            return [null, {}];
        }
        const imagesObj = {} as { [key: string]: Image };
        const [error, images] = await ImageService.getAll({ ids: imagesIds });
        images.forEach((image) => {
            const authorId = map[image?.id];
            imagesObj[authorId] = image;
        });
        return [error, imagesObj];
    }
}

export default new AuthorService();
