<template>
    <sidebar-right :heading="$t('entities.settings')">
        <form class="sidebar__form">
            <div>
                <div v-for="(item, key) in fields" class="form__input" :class="item.class" :key="key">
                    <template v-if="item.grouped">
                        <label class="form-item__label switches-label"> {{ item.title }}</label>
                        <FormItem
                            v-for="groupedItem in item.items"
                            v-bind="groupedItem.props"
                            :key="groupedItem"
                            :name="groupedItem"
                            no-label
                        >
                            <component
                                :is="entity.fields[groupedItem].component"
                                v-model="currentState[groupedItem]"
                                v-bind="entity.fields[groupedItem].props || {}"
                                @input="updateEntity"
                                @update-field="onUpdateFieldValue"
                                :name="groupedItem"
                            ></component>
                        </FormItem>
                    </template>
                    <FormItem v-else v-bind="item.props" :name="item">
                        <component
                            :is="entity.fields[item].component"
                            v-model="currentState[item]"
                            v-bind="entity.fields[item].props || {}"
                            @input="updateEntity"
                            @update-field="onUpdateFieldValue"
                            :name="item"
                        ></component>
                    </FormItem>
                </div>
                <!--                <label class="form-item__label switches-label"> {{ $t('entities.page.fields.pageStatus') }}</label>
                <form-item class="sidebar__switch">
                    <input-switch
                        :text="'builder.pageStatus.enabled'"
                        size="small"
                        name="enabled"
                        @input="updatePage"
                        v-model="currentState.enabled"
                    />
                </form-item>
                <form-item class="sidebar__switch">
                    <input-switch
                        :text="'builder.pageStatus.hidden'"
                        size="small"
                        @input="updatePage"
                        name="hidden"
                        v-model="currentState.hidden"
                    />
                </form-item>
                <form-item class="sidebar__switch">
                    <input-switch
                        :text="'builder.pageStatus.noIndex'"
                        size="small"
                        @input="updatePage"
                        name="noIndex"
                        v-model="currentState.noIndex"
                    />
                </form-item>-->
            </div>
        </form>
    </sidebar-right>
</template>

<script>
import SidebarRight from '@/components/common/SidebarRight';
import { cloneDeep, isEqual, debounce } from 'lodash';
import FormItem from '@/components/form/item';
import InputSwitch from '@/components/form/controls/InputSwitch';

export default {
    name: 'EntityItemSidebar',
    components: { InputSwitch, FormItem, SidebarRight },
    props: {
        entity: {
            type: Object,
        },
        fields: {
            type: Array,
        },
    },
    created() {
        this.currentState = cloneDeep(this.entity);
    },
    data() {
        return {
            renderComponent: true,
            currentState: {},
        };
    },
    methods: {
        onUpdateFieldValue: debounce(function() {
            //TODO: валидация
            this.updateEntity();
        }, 300),

        updateEntity() {
            this.$emit('change', this.currentState);
        },
    },

    watch: {
        entity: {
            handler(val) {
                if (!isEqual(val, this.currentState)) {
                    this.currentState = cloneDeep(val);
                }
            },
            deep: true,
        },
    },
};
</script>

<style lang="scss">
.sidebar {
    &__form {
        padding: 16px;
    }
    &__switch {
        margin-bottom: 15px;
    }
    .switches-label {
        margin-bottom: 15px;
    }
}
</style>
